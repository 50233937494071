export default {
    messages: {
        uk: {
            'Автоматичне поповнення Особистого рахунку': 'Автоматичне поповнення Особистого рахунку',
            'Запропонована сума поповнення': 'Запропонована сума поповнення',
            'Сума поповнення, грн': 'Сума поповнення, грн',
            'Дата та час повторного поповнення': 'Дата та час повторного поповнення',
            'Зверніть увагу!': 'Зверніть увагу!',
            'Автоматично поповнювати особистий рахунок через 30 днів': 'Автоматично поповнювати особистий рахунок через 30 днів',
            'Кошти на поповнення особистого рахунку списуються з основної картки': 'Кошти на поповнення особистого рахунку списуються з основної картки',
            'Збережені дані': 'Збережені дані',
            'У вказані дату й час кошти будуть списані з вашої картки': 'У вказані дату й час кошти будуть списані з вашої картки',
            'Підтвердити платіж': 'Підтвердити платіж',
            'Відміна': 'Відміна',
            'Підтвердіть': 'Підтвердіть',
            'автоматичне': 'автоматичне',
            'поповнення Особистого рахунку': 'поповнення Особистого рахунку',
            'Сума буде': 'Сума буде',
            'нараховуватись кожен місяць': 'нараховуватись кожен місяць',
            'нарахована': 'нарахована',
            'о': 'о',
            'після проведення оплати': 'після проведення оплати',
        },
        ru: {
            'Автоматичне поповнення Особистого рахунку': 'Автоматическое пополнение Личного счета',
            'Запропонована сума поповнення': 'Предлагаемая сумма пополнения',
            'Сума поповнення, грн': 'Сумма пополнения, грн',
            'Дата та час повторного поповнення': 'Дата и время повторного пополнения',
            'Зверніть увагу!': 'Обратите внимание!',
            'Автоматично поповнювати особистий рахунок через 30 днів': 'Автоматически пополнять личный счет через 30 дней',
            'Кошти на поповнення особистого рахунку списуються з основної картки': 'Средства на пополнение счета списываются с основной карты',
            'Збережені дані': 'Сохраненные данные',
            'У вказані дату й час кошти будуть списані з вашої картки': 'В указанные дату и время средства будут списаны с вашей карты.',
            'Підтвердити платіж': 'Подтвердить платеж',
            'Відміна': 'Отмена',
            'Підтвердіть': 'Подтвердите',
            'автоматичне': 'автоматическое',
            'поповнення Особистого рахунку': 'пополнение Личного счета',
            'Сума буде': 'Сумма будет',
            'нараховуватись кожен місяць': 'начисляться каждый месяц',
            'нарахована': 'начислена',
            'о': 'в',
            'після проведення оплати': 'после проведения оплаты',
        }
    }
}
