import faq from './faq.json';

export default {
    name: 'FAQ',
    data() {
        return {
            faq,
        };
    },
    i18n: {
        messages: {
            uk: {
                'Часті запитання': 'Часті запитання',
                'Наскільки безпечно прив’язувати карту, вказуючи всі її реквізити': 'Наскільки безпечно прив’язувати карту, вказуючи всі її реквізити',
                'Система повністю безпечна й відповідає всім сучасним вимогам платіжних систем Visa, MasterCard та PCI DSS. Введення реквізитів банківської карти відбувається через захищене підключення і не зберігається в системі. Відповідно, викрасти їх чи скористатись ними неможливо': 'Система повністю безпечна й відповідає всім сучасним вимогам платіжних систем Visa, MasterCard та PCI DSS. Введення реквізитів банківської карти відбувається через захищене підключення і не зберігається в системі. Відповідно, викрасти їх чи скористатись ними неможливо',
                'Які обмеження мінімальних/максимальних сум платежів': 'Які обмеження мінімальних/максимальних сум платежів',
                'Єдине обмеження, яке передбачено для оплат на AUTO.RIA — до 25 000 гривень за один платіж. Також перевірте, чи немає обмежень, накладених на картку самим банком': 'Єдине обмеження, яке передбачено для оплат на AUTO.RIA — до 25 000 гривень за один платіж. Також перевірте, чи немає обмежень, накладених на картку самим банком',
                'А як відв’язати карту': 'А як відв’язати карту',
                'Це можна зробити в один дотик. Одразу після того, як карта буде прив’язана, на її зображенні на цій сторінці з’явиться кнопка «Видалити». Після натискання на неї картка миттєво буде відв’язана і платежі з неї списуватись не будуть': 'Це можна зробити в один дотик. Одразу після того, як карта буде прив’язана, на її зображенні на цій сторінці з’явиться кнопка «Видалити». Після натискання на неї картка миттєво буде відв’язана і платежі з неї списуватись не будуть',
            },
            ru: {
                'Часті запитання': 'Часто задаваемые вопросы',
                'Наскільки безпечно прив’язувати карту, вказуючи всі її реквізити': 'Насколько безопасно привязывать карту, указывая все ее реквизиты',
                'Система повністю безпечна й відповідає всім сучасним вимогам платіжних систем Visa, MasterCard та PCI DSS. Введення реквізитів банківської карти відбувається через захищене підключення і не зберігається в системі. Відповідно, викрасти їх чи скористатись ними неможливо': 'Система полностью безопасна и отвечает всем современным требованиям платежных систем Visa, MasterCard и PCI DSS. Ввод реквизитов банковской карты производится через защищенное подключение и не хранится в системе. Соответственно, похитить их или воспользоваться ими невозможно',
                'Які обмеження мінімальних/максимальних сум платежів': 'Какие ограничения минимальных/максимальных сумм платежей',
                'Єдине обмеження, яке передбачено для оплат на AUTO.RIA — до 25 000 гривень за один платіж. Також перевірте, чи немає обмежень, накладених на картку самим банком': 'Единственное ограничение, предусмотренное для оплат на AUTO.RIA — до 25 000 гривен за один платеж. Также проверьте, нет ли ограничений, наложенных на карту самим банком',
                'А як відв’язати карту': 'А как отвязать карту',
                'Це можна зробити в один дотик. Одразу після того, як карта буде прив’язана, на її зображенні на цій сторінці з’явиться кнопка «Видалити». Після натискання на неї картка миттєво буде відв’язана і платежі з неї списуватись не будуть': 'Это можно сделать в одно касание. Сразу после того, как карта будет привязана, на ее изображении на этой странице появится кнопка «Удалить». После нажатия на нее карточка будет мгновенно отвязана и платежи с нее списываться не будут',
            }
        }
    },
}
