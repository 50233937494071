import { mapActions, mapGetters } from 'vuex'
import translate from "./i18n";
const PERIOD_PROLONGATION = 1 // 1 Month

export default {
    name: 'Prolongation',
    data() {
        return {
            showConfirmPopup: false
        }
    },
    computed: {
        ...mapGetters( 'cabinet' ,{
            cards: 'cards',
            prolongation: 'billing/prolongation',
        }),
        formatDate() {
            if(!this.prolongation?.prolongationDate) return null
            const date = new Date(this.prolongation.prolongationDate);
            return this.m(date).format('DD.MM.YYYY');
        },
        minDate(){
            return this.getDate(Date.now());
        },
        mainCard() {
            return Object.values(this.cards).find(card => card.main)
        },
    },
    methods: {
        ...mapActions('cabinet/billing', {
            updateProlongation: 'updateProlongationData',
            postProlongation: 'postProlongation'
        }),
        confirmPayment(){
            this.postProlongation();
            this.showConfirmPopup = false;
        },
        updateProlongationData({ name, value }) {
            this.updateProlongation({[name]: value})
        },
        getDate( date, {days, months, years} = {days:0,months:0,years:0}) {
            const startDate = new Date(date)
            return this.m(startDate).add(years,'years').add(months,'months').add(days,'days').format('YYYY-MM-DD');
        },
    },
    init({ store, context }) {
        let Cookies = {};
        if (context) {
            Cookies = context?.request?.headers?.cookie;
        }
        return store.dispatch('cabinet/billing/getProlongationData', Cookies)
    },
    mounted () {
        this.getDate(Date.now(), { months: PERIOD_PROLONGATION })
    },
    i18n: {
        ...translate
    }
}
