export default {
    name: 'InfoBlock',
    i18n: {
        messages: {
            ru: {
                'Зручно і швидко': 'Удобно и быстро',
                'Надійно та перевірено': 'Надежно и проверено',
                'Як прив’язати банківську карту до свого профілю': 'Как привязать банковскую карту к своему профилю',
                'Перейдіть на сторінку платіжної системи': 'Перейдите на страницу платежной системы',
                'Вкажіть номер карти': 'Укажите номер карты',
                'Уточніть термін дії карти': 'Уточните срок действия карты',
                'Заповніть поле CVV-коду (на звороті карти)': 'Заполните поле CVV-кода (с обратной стороны карты)',
                'Підтвердіть надсилання даних через онлайн-банкінг, смс тощо': 'Подтвердите передачу данных через онлайн-банкинг, смс и т.д.',
                'Якщо у вас кілька доданих карт — виберіть для оплат основну': 'Если у вас есть несколько добавленных карт — выберите для оплат основную',
            },
            uk: {
                'Зручно і швидко': 'Зручно і швидко',
                'Надійно та перевірено': 'Надійно та перевірено',
                'Як прив’язати банківську карту до свого профілю': 'Як прив’язати банківську карту до свого профілю',
                'Перейдіть на сторінку платіжної системи': 'Перейдіть на сторінку платіжної системи',
                'Вкажіть номер карти': 'Вкажіть номер карти',
                'Уточніть термін дії карти': 'Уточніть термін дії карти',
                'Заповніть поле CVV-коду (на звороті карти)': 'Заповніть поле CVV-коду (на звороті карти)',
                'Підтвердіть надсилання даних через онлайн-банкінг, смс тощо': 'Підтвердіть надсилання даних через онлайн-банкінг, смс тощо',
                'Якщо у вас кілька доданих карт — виберіть для оплат основну': 'Якщо у вас кілька доданих карт — виберіть для оплат основну',
            }
        }
    }
}
