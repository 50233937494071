import {mapGetters} from 'vuex';
import FAQ from "./FAQ/index.vue";
import ItemCard from "./ItemCard/index.vue";
import InfoBlock from "./InfoBlock/index.vue";
import Prolongation from "./Prolongation/index.vue";

export default {
    name: 'SpecifyCards',
    data: function () {
        return {
            showProlongation: false,
            segmentsArr: ['b2cAutoDealer', 'b2cAutoDealer_exp'],
        };
    },
    components: {
        'info-block': InfoBlock,
        'item-card': ItemCard,
        'faq': FAQ,
        'prolongation': Prolongation,
    },
    computed: {
        ...mapGetters({
            userInfo: 'cabinet/userInfo/userData',
            segment: 'cabinet/segment',
        }),
    },
    mounted() {
        if (this.segmentsArr.includes(this.segment)) {
            this.showProlongation = true;
        }
    },
    i18n: {
        messages: {
            uk: {
                'Вкажіть картку для миттєвих розрахунків': 'Вкажіть картку для миттєвих розрахунків',
            },
            ru: {
                'Вкажіть картку для миттєвих розрахунків': 'Укажите карту для мгновенных расчетов',
            }
        }
    }
}
